export const encodeProjectDetailsQuery = (data) => {
    let querySortByOrderBy = "";
    let queryParams = "";
    for (const d in data) {
        if (data[d] === "DESC" || data[d] === "ASC") {
            querySortByOrderBy += "sortBy=" + encodeURIComponent(d) + "&orderBy=" + encodeURIComponent(data[d]) + "&";
            querySortByOrderBy = querySortByOrderBy.slice(0, -1);
        }
        else {
            queryParams = `${encodeURIComponent(d)}=${data[d]}`;
        }
    }
    return queryParams + `${querySortByOrderBy && "&" + querySortByOrderBy}`;
};
export const isEmpty = (arr) => {
    return Array.isArray(arr) && arr.length ? false : true;
};
